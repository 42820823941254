import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vndCurrency',
})
export class VndCurrencyPipe implements PipeTransform {
  transform(value: number, unit:boolean = true): string {
    if (isNaN(value)) {
      return '';
    }

    const formattedValue = new Intl.NumberFormat('vi-VN').format(value);

    return !unit ? `${formattedValue}` : `${formattedValue} VND`
  }
}

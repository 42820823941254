import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StrapiResponse } from '../types/strapi';
import {
  Category,
  Collection,
  CollectionPage,
  Tag,
} from '../types/collection';

@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  private API_CMS = `${environment.apiStrapi}`;
  collections: Collection[] = [];

  constructor(private httpClient: HttpClient) {}

  getCollections(params?: any): Observable<StrapiResponse<Collection[]>> {
    return this.httpClient.get<StrapiResponse<Collection[]>>(
      `${this.API_CMS}/collections`,
      { params }
    );
  }
  getCategories() {
    let params = new HttpParams();
    params = params.set('populate', 'collections').set('filters[collections][$gt]', '0');
    return this.httpClient.get<StrapiResponse<Category[]>>(
      `${this.API_CMS}/collection-categories
`,
      { params }
    );
  }
  getTags() {
    let params = new HttpParams();
    params = params.set('populate', 'collections').set('filters[collections][$gt]', '0');;
    return this.httpClient.get<StrapiResponse<Tag[]>>(
      `${this.API_CMS}/collection-tags
`,
      { params }
    );
  }
  getGroupOptions() {
    let params = new HttpParams();
    params = params
      .set('populate[0]', 'group_options')
      .set('populate[1]', 'group_options.options');
    return this.httpClient.get<StrapiResponse<CollectionPage['group_options']>>(
      `${this.API_CMS}/collection-page
`,
      { params }
    );
  }
  getCollectionBySlug(slug: string): Observable<StrapiResponse<Collection[]>> {
    let params = new HttpParams();
    params = params
      .set('filters[slug][$eq]', slug || '')
      .set('populate[0]', 'layouts')
      .set('populate[1]', 'layouts.thumbnail')
      .set('populate[2]', 'layouts.full_image')
      .set('populate[3]', 'categories')
      .set('populate[4]', 'tags');

    return this.httpClient.get<StrapiResponse<Collection[]>>(
      `${this.API_CMS}/collections
`,
      { params }
    );
  }
  getCollectionPage() {
    let params = new HttpParams();
    params = params
      .set('fields[0]', 'heading')
      .set('fields[1]', 'text')
      .set('populate[1]', 'group_options')
      .set('populate[2]', 'group_options.options');

    return this.httpClient.get<StrapiResponse<CollectionPage>>(
      `${this.API_CMS}/collection-page
`,
      { params }
    );
  }
  sendEmailToSalesTeam(payload){
    return this.httpClient.post<StrapiResponse<CollectionPage>>(
      `${this.API_CMS}/send-email-sequentially`, payload
    );
  }

}

<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-3">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a href="#"> Trang Chủ</a></li>
                <li>
                  <a href="/collection">Bộ Sưu Tập</a>
                </li>
                <li>
                  <a href="#">{{ collection.title }}</a>
                </li>
              </ul>
            </div>
            <div class="bread-title">
              <h2>{{ currentLayoutShow?.title }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start collection-->
<section class="collection-single-page pad-tb">
  <div class="container">
    <section class="row justify-content-center collection-single-layout">
      <div class="col-md-6 col-lg-5">
        <div class="image-block upset bg-shape">
          <a
            *ngIf="currentLayoutShow?.url_demo"
            target="_blank"
            href="{{ currentLayoutShow?.url_demo }}"
            class="btn-preview-collection"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-aspect-ratio"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"
              />
              <path
                d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0z"
              />
            </svg>
            Xem trực tiếp</a
          >
          <div class="image-div">
            <div class="scroll-div">
              <img
                src="{{ currentLayoutShow?.full_image?.url }}"
                alt="{{ currentLayoutShow?.title }}"
                class="img-fluid rounded-0"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-7">
        <div class="full-image-card mt0 d-flex flex-column">
          <div class="info-categories order-0">
            <a
              *ngFor="let category of collection.categories; trackBy: trackByFn"
              routerLink="/collection"
              [queryParams]="{ categories: category?.slug }"
              >{{ category?.title }}</a
            >
          </div>
          <div class="info-text-block order-1">
            <h4>
              {{ collection.title + " " + currentLayoutShow?.title }}
            </h4>
            <div class="price-block">
              <div class="text-muted">Tạm tính</div>
              <div class="price">
                <b>{{ collection.price_end | vndCurrency }}</b>
              </div>
            </div>
          </div>
          <div class="buy-template order-2 order-md-3">
            <a
              href="#"
              data-toggle="modal"
              data-target="#modal-final-form"
              class="btn-buy-template btn-main collection lnk"
            >
              <i class="fas fa-shopping-cart"></i>
              Mua trang web này
              <span class="circle"></span>
            </a>
            <a
              (click)="scrollToSection('option-section')"
              class="btn-option cursor-pointer"
              >Thêm tùy chỉnh</a
            >
          </div>
          <div class="otherinfo order-4 order-md-2">
            <div class="ck-content" [innerHTML]="collection.short_description" *ngIf="collection?.short_description">
            </div>
          </div>

          <div class="layout-in-template order-3 order-md-4">
            <a
              class="layout-template-item"
              *ngFor="let layout of collection.layouts; trackBy: trackByFn"
              data-id="{{ layout?.slug }}"
              [routerLink]="['/collection', collection?.slug, layout?.slug]"
              [ngClass]="{ active: currentLayoutShow.slug === layout?.slug }"
            >
              <figure>
                <img
                  src="{{ layout?.thumbnail?.url }}"
                  alt="{{ layout?.title }}"
                  loading="lazy"
                />
              </figure>
              <div class="layout-template-item-name">
                <h4 class="line-clamp-1 line-clamp">{{ layout?.title }}</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="desc-section" *ngIf="collection?.showDescription">
      <h4 class="filter-title underline underline-pink">Mô Tả</h4>
      <div
        class="desc-pad ck-content"
        [innerHTML]="collection.description"
      ></div>
    </section>
    <section
      class="option-section"
      id="option-section"
      *ngIf="groupOptions.length > 0"
    >
      <h4 class="filter-title underline underline-pink">Thêm Tùy Chỉnh</h4>
      <div class="text-right text-muted">
        <span style="font-size: 12px">Đơn vị tiền tệ: VND</span>
      </div>
      <div class="options-pad">
        <div
          *ngFor="let group of groupOptions; trackBy: trackByFn"
          class="options-pad-item"
        >
          <h4>{{ group?.title | titlecase }}</h4>
          <div class="options-pad-item-grid">
            <div
              class="option-tag"
              *ngFor="let option of group.options; trackBy: trackByFn"
            >
              <label for="{{ option.id }}" class="option-trigger">
                <input
                  type="checkbox"
                  id="{{ option.id }}"
                  name="{{ option.id }}"
                  value="{{ option.id }}"
                  checked="{{ option.checked }}"
                  style="display: none"
                  (change)="onSelectOption(group.id, option.id, $event)"
                />

                <div class="option-info">
                  <div class="option-info-name">
                    {{ option.title }}
                  </div>
                  <div class="option-info-bill">
                    <div
                      class="option-info-quantity"
                      [ngClass]="{
                        fake: !option?.allow_quantity
                      }"
                    >
                      <input
                        *ngIf="option?.allow_quantity"
                        title="{{ option.title }}"
                        type="number"
                        id="gId-{{ group.id }}-oId-{{ option.id }}"
                        name="gId-{{ group.id }}-oId-{{ option.id }}"
                        class="option-info-quantity-input"
                        [(ngModel)]="option.quantity"
                        [value]="option.quantity"
                        (ngModelChange)="
                          onChangeOptionItemQuantity(
                            group.id,
                            option.id,
                            $event
                          )
                        "
                        (input)="onInputQuantityChange($event)"
                        min="1"
                        max="999"
                      />
                      <div
                        class="option-info-quantity-control"
                        *ngIf="option?.allow_quantity"
                      >
                        <button
                          type="button"
                          class="option-info-quantity-control-btn"
                          (click)="
                            controlQuantityForOption(
                              'increment',
                              group.id,
                              option.id
                            )
                          "
                        >
                          +
                        </button>
                        <button
                          type="button"
                          (click)="
                            controlQuantityForOption(
                              'decrement',
                              group.id,
                              option.id
                            )
                          "
                        >
                          -
                        </button>
                      </div>
                    </div>
                    <span
                      class="option-info-price"
                      title="{{ option.price_end | vndCurrency }}"
                      >{{ option.price_end | vndCurrency : false }}</span
                    >
                  </div>
                  <span class="option-checked">
                    <i class="fas fa-check fa-2xs"></i>
                  </span>
                </div>
              </label>
              <div class="option-tag-desc-pad" *ngIf="option.showDescription">
                <span class="option-desc-icon"
                  ><i class="fas fa-question fa-2xs"></i
                ></span>
              </div>
              <div
                class="popup-desc ck-content minimal"
                *ngIf="option.showDescription"
                [innerHTML]="option.description"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="related-section"
      id="related-section"
      *ngIf="relatedCollections.length > 0"
    >
      <h4 class="filter-title underline underline-pink">Bạn Có Thể Thích</h4>
      <div class="card-grid">
        <div
          class="website single-card-item fade-in"
          *ngFor="let template of relatedCollections; trackBy: trackByFn"
        >
          <div class="card-item isotope_item hover-scale">
            <div class="item-image">
              <a
                target="_blank"
                [routerLink]="
                  template?.layouts?.length > 0
                    ? [
                        '/collection',
                        template?.slug,
                        template?.layouts[0]?.slug
                      ]
                    : ['/collection', template?.slug]
                "
                ><img
                  src="{{ template?.thumbnail?.url }}"
                  alt="{{ template?.thumbnail?.alternativeText }}"
                  class="img-fluid"
                  loading="lazy"
                />
              </a>
            </div>
            <div class="item-info">
              <h4 class="line-clamp line-clamp-2">
                <a
                  target="_blank"
                  [routerLink]="
                    template?.layouts?.length > 0
                      ? [
                          '/collection',
                          template?.slug,
                          template?.layouts[0]?.slug
                        ]
                      : ['/collection', template?.slug]
                  "
                >
                  {{ template.title }}
                </a>
              </h4>
              <p style="color: #ff1f8e">
                Từ {{ template.basic_price | vndCurrency }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="bottom-bar" [ngClass]="{ active: showBottomBar }">
    <div class="container">
      <div class="row">
        <div
          class="col-12 d-none d-md-flex col-md-6 flex-wrap align-items-center"
        >
          <h4>{{ collection.title }}</h4>
        </div>
        <div
          class="col-12 col-md-6 d-flex align-items-center justify-content-between"
        >
          <div class="price-block">
            <span class="text-muted text-white">Tạm tính</span>
            <div class="price">
              <b>{{ collection.price_end | vndCurrency }}</b>
            </div>
          </div>
          <a
            href="#"
            data-toggle="modal"
            data-target="#modal-final-form"
            class="btn-main collection lnk"
          >
            <i class="fas fa-shopping-cart"></i>
            Mua trang web này
            <span class="circle"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="popup-modals form-buy">
  <div
    class="modal fade"
    id="modal-final-form"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <div class="common-heading">
            <h4 class="mt0 mb0">Mua trang web</h4>
          </div>
          <button
            (click)="resetFormSubmitState()"
            aria-label="Close"
            tabindex="-1"
            type="button"
            class="closes"
            data-dismiss="modal"
            *ngIf="!submmitting"
          >
            &times;
          </button>
        </div>
        <div class="modal-body" #modalBody>
          <form [formGroup]="finalForm" (ngSubmit)="onSubmit()">
            <div
              class="alert alert-success d-flex fade-in"
              role="alert"
              *ngIf="submittedSuccess"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check-circle-fill d-inline-block mr-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                />
              </svg>
              Gửi biểu mẫu thành công.
            </div>
            <div
              class="alert alert-danger d-flex"
              role="alert"
              *ngIf="submittedError"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-triangle-fill d-inline-block mr-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
                />
              </svg>
              Đã xảy ra lỗi.
            </div>
            <div class="modal-body__block">
              <span class="collection-id">#{{ collection?.uuid }}</span>
              <h3 class="collection-title">{{ collection?.title }}</h3>
            </div>
            <div
              class="modal-body__block"
              *ngIf="getSelectedOptions().length > 0"
            >
              <div class="modal-body__block__title">
                Tùy chỉnh chọn thêm ({{ getSelectedOptions().length }})
              </div>
              <div class="modal-body__block__content">
                <p class="text-muted">Đơn vị tiền tệ: VND</p>
                <ul class="collection-options">
                  <li
                    class="collection-options__item"
                    *ngFor="
                      let group of getSelectedOptions();
                      index as i;
                      trackBy: trackByFn
                    "
                  >
                    <span class="_quantity">x{{ group?.quantity }}</span>
                    <div class="_title" title="{{ group?.title }}">
                      {{ group?.title }}
                    </div>
                    <span
                      title="{{ group?.price_end | vndCurrency }}"
                      class="_price"
                      >{{ group?.price_end | vndCurrency : false }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <fieldset class="modal-body__block" [disabled]="submmitting">
              <div class="modal-body__block__title">Thông tin liên hệ</div>
              <div class="modal-body__block__content" #formBody>
                <div class="form-grid">
                  <div class="form-group">
                    <div class="form-item">
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        formControlName="name"
                        placeholder="Tên*"
                        required
                      />
                      <div
                        class="feedback"
                        [ngClass]="{
                          'invalid-feedback':
                            submitted && getFormState.name?.invalid
                        }"
                      >
                        <span *ngIf="getFormState.name?.errors?.required">
                          Vui lòng điền Tên.
                        </span>
                      </div>
                    </div>
                    <div class="form-item">
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        formControlName="email"
                        placeholder="Email*"
                        required
                      />
                      <div
                        class="feedback"
                        [ngClass]="{
                          'invalid-feedback':
                            submitted && getFormState.email?.invalid
                        }"
                      >
                        <span *ngIf="getFormState.email?.errors?.required">
                          Vui lòng điền Email.
                        </span>
                        <span *ngIf="getFormState.email?.errors?.email">
                          Email không hợp lệ.
                        </span>
                      </div>
                    </div>
                    <div class="form-item">
                      <input
                        type="text"
                        class="form-control"
                        id="phone"
                        formControlName="phone"
                        placeholder="Số điện thoại*"
                        required
                      />
                      <div
                        class="feedback"
                        [ngClass]="{
                          'invalid-feedback':
                            submitted && getFormState.phone?.invalid
                        }"
                      >
                        <span *ngIf="getFormState.phone?.errors?.required">
                          Vui lòng điền Số điện thoại.
                        </span>
                        <span *ngIf="getFormState.phone?.errors?.pattern">
                          Số điện thoại không hợp lệ.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-item">
                      <textarea
                        class="form-control"
                        id="message"
                        formControlName="message"
                        rows="5"
                        placeholder="Lời nhắn"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="modal-body__block" [disabled]="submmitting">
              <div class="modal-body__block__title">Yêu cầu chi tiết</div>
              <div class="modal-body__block__content">
                <p class="text-desc">
                  Dữ liệu sẽ chỉ được sử dụng trong dự án, Dicom Interactive cam
                  kết không chia sẽ thông tin đến các bên thứ ba.
                </p>
                <div class="input-file-pdf">
                  <input
                    id="file"
                    type="file"
                    style="display: none"
                    accept="application/pdf"
                    (change)="onFileChange($event)"
                  />
                  <label for="file">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-upload"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"
                      />
                      <path
                        d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"
                      />
                    </svg>
                    <span>
                      {{
                        this.finalForm.value?.file?.name
                          ? this.finalForm.value.file.name
                          : "Tải tệp (tối đa 10MB)"
                      }}
                    </span>
                  </label>
                </div>
                <div
                  class="feedback extra"
                  [ngClass]="{
                    'invalid-feedback': submitted && getFormState.file?.invalid
                  }"
                >
                  <!-- <span *ngIf="getFormState.file?.errors?.required">
                    Vui lòng tải lên tệp PDF 10MB.
                  </span> -->
                  <span *ngIf="getFormState.file?.errors?.invalidFileType">
                    Tệp phải là PDF.
                  </span>
                  <span *ngIf="getFormState.file?.errors?.invalidFileSize"
                    >Kích thước tệp không được vượt quá 10MB.</span
                  >
                </div>
              </div>
            </fieldset>
            <fieldset class="modal-body__block" [disabled]="submmitting">
              <div class="modal-body__block__content">
                <div class="final-info__block">
                  <div class="price-block">
                    <span>Tạm tính</span>
                    <div class="_price">
                      <b>{{ collection?.price_end | vndCurrency }}</b>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn-main collection lnk"
                    *ngIf="!submmitting"
                  >
                    <i class="fas fa-shopping-cart"></i>
                    Mua
                    <span class="d-md-inline-block d-none">trang web này</span>
                    <span class="circle"></span>
                  </button>
                  <button
                    type="submit"
                    class="btn-main collection lnk"
                    *ngIf="submmitting"
                  >
                    Đang gửi...
                    <div class="spinner-border spinner-border-sm"></div>
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!--End Portfolio-->

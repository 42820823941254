import { Injectable } from '@angular/core';
import { Category, Collection, CollectionPage, Tag } from '../types/collection';

@Injectable({
  providedIn: 'root',
})
export class CollectionCacheService {
  collections: Collection[] = [];
  categories: Category[] = [];
  tags: Tag[] = [];
  postionScroll: number = 0;
  start: number = 0;
  limit: number = 0;
  total: number = 0;
  page: CollectionPage = {
    text: '',
    id: 0,
    documentId: '',
    group_options: [],
    heading: '',
  };

  setCache(obj: {
    collections?: Collection[];
    categories?: Category[];
    tags?: Tag[];
    start?: number;
    limit?: number;
    total?: number;
    postionScroll?: number;
    page?: CollectionPage;
  }) {
    for (let key in obj) {
      if (obj[key] !== undefined) {
        this[key] = obj[key];
      }
    }
  }

  getCache() {
    return {
      collections: this.collections,
      categories: this.categories,
      tags: this.tags,
      start: this.start,
      limit: this.limit,
      total: this.total,
      postionScroll: this.postionScroll,
      page: this.page,
    };
  }

  clearCache() {
    this.collections = [];
    this.postionScroll = 0;
    this.start = 0;
    this.limit = 0;
    this.total = 0;
  }
}
